import React, {useContext, useEffect, useState} from "react";
import {Box, Button, ButtonProps} from "@mui/material";
import {styled} from "@mui/material/styles";

interface Size {
    width: number;
    height: number;
}

const DEFAULT_SIZE: Size = {width: 1, height: 1};
const DEFAULT_HOVER_SIZE: Size = {width: 0, height: 0};
const DEFAULT_MAX_WIDTH = 1;
const DEFAULT_MAX_HEIGHT = 1;

interface SizeSelectorProps {
    maxWidth: number;
    maxHeight: number;
    onSizeChange: (width: number, height: number) => void;
}

interface ISizeSelectorContext {
    maxWidth: number;
    maxHeight: number;
    selectedSize: Size;
    setSelectedSize: (size: Size) => void;
    hoveredSize: Size;
    setHoveredSize: (size: Size) => void;
}

const SizeSelectorContext = React.createContext<ISizeSelectorContext>({
    maxWidth: DEFAULT_MAX_WIDTH,
    maxHeight: DEFAULT_MAX_HEIGHT,
    selectedSize: DEFAULT_SIZE,
    setSelectedSize: () => {},
    hoveredSize: DEFAULT_HOVER_SIZE,
    setHoveredSize: () => {}
});

function SizeSelector(props: SizeSelectorProps) {
    const [selectedSize, setSelectedSize] = useState(DEFAULT_SIZE);
    const [hoveredSize, setHoveredSize] = useState(DEFAULT_HOVER_SIZE);

    function clearHover() {
        setHoveredSize({width: selectedSize.width, height: selectedSize.height});
    }

    useEffect(() => {
        props.onSizeChange(selectedSize.width, selectedSize.height);
    }, [selectedSize]);

    return (
        <SizeSelectorContext.Provider value={{
            maxWidth: props.maxWidth,
            maxHeight: props.maxHeight,
            selectedSize: selectedSize,
            setSelectedSize: setSelectedSize,
            hoveredSize: hoveredSize,
            setHoveredSize: setHoveredSize
        }}>
            <Box component="div" onMouseLeave={clearHover} sx={{width: 320, marginLeft: "auto", marginRight: "auto"}}>
                {
                    [...Array(props.maxHeight)].map((row, yValue) => <SizeSelectorRow yValue={yValue + 1} /> )
                }
            </Box>
        </SizeSelectorContext.Provider>
    )
}

interface SizeSelectorRowProps {
    yValue: number;
}

function SizeSelectorRow(props: SizeSelectorRowProps) {
    const { maxWidth } = useContext(SizeSelectorContext);

    return (
        <Box>
            {
                [...Array(maxWidth)].map((col, xValue) =>
                    <SizeSelectorCell yValue={props.yValue}
                                      xValue={xValue + 1}/>
                )
            }
        </Box>
    )
}

interface SizeSelectorCellProps {
    xValue: number;
    yValue: number;
}

function SizeSelectorCell(props: SizeSelectorCellProps) {
    const {selectedSize, setSelectedSize, hoveredSize, setHoveredSize} = useContext(SizeSelectorContext);

    return (
        <Box component="span">
            <StyledButton sx={{width: 64, height: 64}}
                          disableRipple={true}
                          onClick={ handleClick }
                          onMouseOver={ handleHover }
                          inSelection={selectedSize.width >= props.xValue && selectedSize.height >= props.yValue}
                          inHover={hoveredSize.width >= props.xValue && hoveredSize.height >= props.yValue && (hoveredSize.width !== 0 && hoveredSize.height !== 0)}>
                {props.xValue} x {props.yValue}
            </StyledButton>
        </Box>
    )

    function handleClick(): void {
        setSelectedSize({width: props.xValue, height: props.yValue});
    }

    function handleHover(): void {
        setHoveredSize({width: props.xValue, height: props.yValue});
    }
}

interface StyledButtonProps extends ButtonProps {
    inSelection?: boolean;
    inHover?: boolean;
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "inHover" && prop !== "inSelection",
})<StyledButtonProps>(({ inSelection, inHover }) => ({
    backgroundColor: "white",
    color: "black",
    borderRadius: 0,
    "&:hover": {
        backgroundColor: "#aaaaaa"
    },
    ...(inHover && {
        backgroundColor: "#aaaaaa",
        color: "white",
        "&:hover": {
            backgroundColor: "#aaaaaa"
        },
    }),
    ...(inSelection && {
        backgroundColor: "#aaaaaa",
        color: "white",
        "&:hover": {
            backgroundColor: "#aaaaaa"
        },
    }),
    ...(inHover && inSelection && {
        backgroundColor: "#888888",
        color: "white",
        "&:hover": {
            backgroundColor: "#888888"
        },
    })
}));

export default SizeSelector;
