import React from "react";
import {Box, Button, Container, Link, List, ListItem, Paper, Stack, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";
import {MAP_ART_CREATOR_PATH, WORLD_EDIT_GUIDE_PATH} from "../../constants/route-constants";

function Litematica() {
    const handleSchematicDownloadClick = () => {
        ReactGA.event({
            category: "MapMakerApp",
            action: "schematic_download",
            label: "tiger",
        });
        return false;
    };

    return (
        <Container maxWidth="lg" sx={{marginTop: 2}}>
            <Helmet>
                <title>Map Schematics with Litematica</title>
            </Helmet>
            <Stack spacing={2}>
                <Paper elevation={0}>
                    <Typography variant="h1" id="main_heading">
                        Map Schematics with Litematica
                    </Typography>
                    <Typography variant="body1">
                        Litematica is a client side mod that allows you to place a holographic rendering of a schematic. This is useful for manually building the map in a Minecraft world where you don't have the ability to automatically place the schematic blocks(if you do, take a look at this <Link href={WORLD_EDIT_GUIDE_PATH}>WorldEdit guide</Link> to automatically place it). This guide will walk you through using Litematica to place a generated map schematics to get a holographic rendering to use as a guide when building the map. If you do not already have the Litematica mod you will need to get it before continuing. <Link href="https://www.curseforge.com/minecraft/mc-mods/litematica">Litematica</Link> also requires the <Link href="https://www.curseforge.com/minecraft/mc-mods/malilib">MaLiLib</Link> library, both of which can be downloaded from CurseForge and installed accordingly based on which mod loader you are using. I personally use Fabric with MultiMC.
                    </Typography>
                    <List>
                        <ListItem><Link href="#prepare-schematic">Prepare a Schematic</Link></ListItem>
                        <ListItem><Link href="#obtain-fill-map">Obtain and Fill a Map</Link></ListItem>
                        <ListItem><Link href="#loading-schematic">Loading a Schematic</Link></ListItem>
                        <ListItem><Link href="#positioning-schematic">Positioning a Schematic</Link></ListItem>
                        <ListItem><Link href="#getting-materials-list">Getting a Materials List</Link></ListItem>
                        <ListItem><Link href="#building-map">Building a Map</Link></ListItem>
                        <ListItem><Link href="#now-what">Now What?</Link></ListItem>
                    </List>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="prepare-schematic">
                        Prepare a Schematic
                    </Typography>
                    <Typography variant="body1">
                        Generate and download a schematic using the <Link href={ MAP_ART_CREATOR_PATH }>map art creator</Link>. Copy the downloaded schematic to the game's schematics directory. This is usually located at <Typography variant="code">&lt;minecraft_installation_location&gt;/schematics</Typography>. An easy way to find this directory is from the in-game menu to go to <Typography variant="italic">"Options" -&gt; "Resource Packs..." -&gt; "Open Pack Folder"</Typography>. This should open up your file browser and you should be able to go up one directory and then to <Typography variant="code">/schematics</Typography>.
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="obtain-fill-map">
                        Obtaining and Filling a Map
                    </Typography>
                    <Typography variant="body1">
                        Obtain a map. Depending on what type of world you are playing on, either craft or give yourself a map.
                        <ol>
                            <li>
                                Maps can be crafted with the following recipe:<br/>
                                <img src={`${process.env.PUBLIC_URL}/worldedit_guide/map_recipe.png`} alt="Map crafting recipe"></img>
                            </li>
                            <li>
                                Maps can be placed in your inventory by running this command(operator privileges will be needed), replacing <Typography variant="code">player_name</Typography> with your in-game name<br/>
                                <Typography variant="code">/give &lt;player_name&gt; minecraft:map</Typography>
                            </li>
                        </ol>
                        While standing within the area you wish to place the schematic, right click with the map in your hand. The following is important to note when preparing the area in which you will build or place the schematic:
                        <figure>
                            <blockquote cite="https://minecraft.fandom.com/wiki/Map#Mapping">
                                <p>The map does not center on the player when created, rather, the world is broken up into large invisible grid squares, and the map displays the area of whichever grid square it is in when it is first used.</p>
                            </blockquote>
                            <figcaption><Link href="https://minecraft.fandom.com/wiki/Map#Mapping" target="_blank">-Minecraft Wiki</Link></figcaption>
                        </figure>
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="loading-schematic">Loading a Schematic</Typography>
                    <Typography variant="body1">
                        Press <Typography variant="italic">"M"</Typography> to open the Litematica menu. Go to <Typography variant="italic">"Load Schematics"</Typography>. You should see a list of available schematics which are from the directory you placed the schematic in earlier. Select the schematic from the list that you wish to build and click <Typography variant="italic">"Load Schematic"</Typography>. A hologram of the map schematic should appear in the world, if not open up the Litematica menu again by pressing <Typography variant="italic">"M"</Typography> and go to <Typography variant="italic">"Loaded Schematics"</Typography>. Select the schematic you just loaded and click <Typography variant="italic">"Create placement"</Typography>. There should now be a hologram rendering of the schematic in the world.
                    </Typography>
                    <Box sx={{ textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/litematica_menu.png`} alt="Litematica menu"></img>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/schematics_list.png`} alt="Litematica schematics list"></img>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/schematic_placement_1.png`} alt="Litematica schematic in the world"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="positioning-schematic">Positioning a Schematic</Typography>
                    <Typography variant="body1">
                        Your main tool in Litematica is a stick. Hold a stick in your main hand and make sure it is in <Typography variant="bold">schematic placement</Typography> mode. To get it into this mode hold <Typography variant="code">CTRL</Typography> and scroll until it is in schematic placement mode, this can be seen in the bottom left area of the screen.
                    </Typography>
                    <Typography variant="body1">
                        To move the schematic, hold down <Typography variant="code">ALT</Typography> while still holding a stick and scroll. This will cause the schematic to move along the axis you are facing, either forward or backward depending on which way you scroll. Using this method, keep moving the schematic until it is correctly lined up in the area you will be building.
                    </Typography>
                    <Box sx={{ textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/mode.png`} alt="Litematica schematic mode"></img>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/schematic_placement_2.png`} alt="Litematica schematics moved into place"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="getting-materials-list">Getting a Materials List</Typography>
                    <Typography variant="body1">
                        To get a list of materials you will need for building the schematic, press <Typography variant="italic">"M"</Typography> to open the Litematica menu. Go to <Typography variant="italic">"Schematic Placements"</Typography>, select <Typography variant="italic">"Configure"</Typography> for the one you will be building and then click <Typography variant="italic">"Material List"</Typography> in the bottom left. You will be presented with a table of materials and how much of each of them you will need. In the table, Total = total number of blocks of this type in the schematic, Missing = number of blocks of this type in the schematic that are not yet placed, and Available = number of blocks of this type in your inventory.
                    </Typography>
                    <Box sx={{textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/materials_list.png`} alt="Litematica materials list"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="building-map">Building a Map</Typography>
                    <Typography variant="body1">
                        You're now ready to start building the map. Go ahead and start placing blocks using the schematic as a guide. Incorrectly placed blocks will be highlighted in red. You can also use Litematica's schematic validator to detect any blocks that are missing or incorrect.
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="now-what">
                        Now What?
                    </Typography>
                    <Typography variant="body1">
                        If there are any areas on the map that are not yet drawn or updated, move to that area while holding the map to get it to update.
                    </Typography>
                    <Typography variant="body1">
                        You can "frame" your artwork by placing the map in an item frame.
                    </Typography>
                    <Box sx={{textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/framed_map.png`} alt="Minecraft map art in item frame"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="body1">
                        Using a glow item frame will make the picture light up in the dark.
                    </Typography>
                    <Box sx={{textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/litematica_guide/framed_map_glowing.png`} alt="Minecraft map art in glow item frame"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="body1">
                        You can lock the map with a glass pane at a cartography table so that it remains in it's current state and new changes to the area don't affect it.
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Box>
                        <Button variant="contained" startIcon={<Download />} href="/litematica_guide/tiger.schem" download onClick={handleSchematicDownloadClick}>
                            Download this Schematic
                        </Button>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Link href="https://www.freepik.com/free-photo/selective-focus-shot-tiger_10809788.htm#query=tiger&position=33&from_view=search&track=sph" target="_blank">Original Image by wirestock</Link> on Freepik
                </Paper>
            </Stack>
        </Container>
    );
}

export default Litematica;
