import TextureRadioGroup from "./TextureRadioGroup";
import {Divider, Grid, Paper} from "@mui/material";
import colors from "../../../colors";
import React, {Fragment} from "react";
import SizeSelector from "./SizeSelector";

interface ISettingsProps {
    handleBlockPreferenceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleDimensionChange: (width: number, height: number) => void;
}

const sliceColors = (cols: number) => {
    const colorEntries = Object.entries(colors);
    const colorsLength = colorEntries.length;
    const sliceSize = Math.ceil(colorsLength / cols);
    const slicedColors = [];
    for(let i = 0; i < cols; i++) {
        const slice = colorEntries.slice(i*sliceSize, Math.min((i+1)*sliceSize, colorsLength));
        slicedColors.push(slice);
    }
    return slicedColors;
}

function Settings(props: ISettingsProps) {
    const blockSelectorColCount = 2;
    const slicedColors = sliceColors(blockSelectorColCount);

    const colorEntries = Object.entries(colors);
    const colorsLength = colorEntries.length;
    const sliceSize = Math.ceil(colorsLength / blockSelectorColCount);

    return (
        <Grid container direction="column" sx={{padding:"8px"}} spacing={2}>
            <Grid item xl={12}>
                <Divider>DIMENSIONS</Divider>
            </Grid>
            <Grid item>
                <Paper elevation={2} sx={{padding: 1, textAlign: "center"}}>
                    <SizeSelector maxWidth={5} maxHeight={5} onSizeChange={props.handleDimensionChange}></SizeSelector>
                </Paper>
            </Grid>
            <Grid item xl={12}>
                <Divider>BLOCK PALETTE</Divider>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    {slicedColors.map((colors_slice, idx) => {
                        return (<Grid item md={6} key={idx}>
                            <Paper elevation={2} sx={{padding: 1}}>
                                {colors_slice.map(([color, textures], index) => (
                                    <Fragment key={index}>
                                        <TextureRadioGroup
                                            textures={textures as string[]}
                                            index={(idx * sliceSize) + index}
                                            color={color}
                                            handleBlockPreferenceChange={props.handleBlockPreferenceChange}></TextureRadioGroup>
                                        <Divider></Divider>
                                    </Fragment>
                                ))}
                            </Paper>
                        </Grid>)
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Settings;
