import React, {useEffect} from "react";
import {MapData} from "../MapMakerApp";
import colors from "../../colors";
import humanizeString from "humanize-string";

interface AltTextureGridProps {
    mapData?: MapData
}

const TOOLTIP_FONT_SIZE = 16;
const TOOLTIP_HEIGHT = 40;
const TOOLTIP_PADDING = 7;
const TOOLTIP_BACKGROUND_COLOR = "white";
const TOOLTIP_COLOR = "black";
const TOOLTIP_WIDTH_MAX = 200;

const TEXTURE_SIZE = 16;

function TextureGrid(props: AltTextureGridProps) {
    useEffect(() => {
        if(props.mapData) {
            const mapMakerContainer = document.getElementById("mapmakerapp-container") as HTMLElement;

            const gridCanvas = document.getElementById("gridCanvas") as HTMLCanvasElement;
            const gridCanvasContext = gridCanvas.getContext("2d");
            const tooltipCanvas = document.getElementById("tooltipCanvas") as HTMLCanvasElement;
            const tooltipCanvasContext = tooltipCanvas.getContext("2d");
            const tooltipCanvasOffset = gridCanvas.getBoundingClientRect();
            if (tooltipCanvasContext === null || gridCanvasContext == null) {
                // TODO: Handle this.
                return;
            }

            // Draw texture grid
            const textureImage = new Image();
            textureImage.onload = () => {
                if(props.mapData) {
                    gridCanvas.width = props.mapData.data[0].length * TEXTURE_SIZE;
                    gridCanvas.height= props.mapData.data.length * TEXTURE_SIZE;
                    props.mapData.data.forEach((rowData, i) => {
                        rowData.forEach((cellData, k) => {
                            const xPos = k * TEXTURE_SIZE;
                            const yPos = i * TEXTURE_SIZE;
                            gridCanvasContext.drawImage(
                                textureImage,
                                0,
                                Object.keys(colors).indexOf(cellData.toString()) * TEXTURE_SIZE,
                                TEXTURE_SIZE,
                                TEXTURE_SIZE,
                                xPos,
                                yPos,
                                TEXTURE_SIZE,
                                TEXTURE_SIZE);
                            gridCanvasContext.strokeStyle = "black";
                            gridCanvasContext.lineWidth = 1;
                            gridCanvasContext.strokeRect(xPos, yPos, TEXTURE_SIZE, TEXTURE_SIZE);
                        });
                    });
                }
            };
            textureImage.src = `${process.env.PUBLIC_URL}/textures.png`

            // Set up dragging and tooltip
            let xCoord: number, yCoord: number;
            let dragging = false;
            let lastX = 0;
            let lastY = 0;
            let downX = 0;
            let downY = 0;
            let tooltipWidth = TOOLTIP_WIDTH_MAX;

            gridCanvas.onmousedown = (event) => {
                dragging = true;
                tooltipCanvasContext.clearRect(0, 0, TOOLTIP_WIDTH_MAX, TOOLTIP_HEIGHT);
                lastX = downX = event.clientX;
                lastY = downY = event.clientY;
            };

            gridCanvas.onmousemove = (event) => {
                if(dragging) {
                    const diffX: number = lastX - event.clientX;
                    const diffY: number = lastY - event.clientY;
                    mapMakerContainer.scrollTop += diffY;
                    mapMakerContainer.scrollLeft += diffX;
                    lastX = event.clientX;
                    lastY = event.clientY;
                }
            };

            gridCanvas.onmouseup = (event: MouseEvent) => {
                dragging = false;
                gridCanvas.style.cursor = "pointer";
            };

            gridCanvas.onmouseleave = (event: MouseEvent) => {
                dragging = false;
            }

            gridCanvas.onclick = (event: MouseEvent) => {
                if(!(event.clientX == downX && event.clientY == downY)) {
                    /*
                     If the mouse has moved since the mousedown event, probably means we're dragging
                     and not clicking, so do nothing to avoid the tooltip randomly popping up when we stop dragging.
                     */
                    return;
                }

                // Tooltip
                const scrollX = mapMakerContainer.scrollLeft;
                const scrollY = mapMakerContainer.scrollTop;
                const eventX = event.clientX;
                const eventY = event.clientY;
                const relativeX = eventX - tooltipCanvasOffset.left;
                const relativeY = eventY - tooltipCanvasOffset.top;
                const xCoordNew = Math.floor((relativeX + scrollX) / TEXTURE_SIZE);
                const yCoordNew = Math.floor((relativeY + scrollY) / TEXTURE_SIZE);

                if(xCoordNew === xCoord && yCoordNew === yCoord) {
                    /*
                     Block coords have not changed since the last click. No need to redraw the exact same tooltip.
                     */
                    return;
                }
                xCoord = xCoordNew;
                yCoord = yCoordNew;
                const coordStr = `${xCoord}, ${yCoord}`;

                let textureIndex;
                let blockName = "";
                if(props.mapData !== undefined && props.mapData.blockPreference !== undefined) {
                    const decColor = props.mapData.data[yCoord][xCoord];
                    textureIndex = props.mapData.blockPreference[decColor] || 0;
                    blockName = formatBlockName(colors[decColor][textureIndex]);
                }

                tooltipCanvasContext.clearRect(0, 0, TOOLTIP_WIDTH_MAX, TOOLTIP_HEIGHT);
                tooltipCanvasContext.font = tooltipCanvasContext.font.replace(/\d+px/, `${TOOLTIP_FONT_SIZE}px`);
                tooltipWidth = Math.max(tooltipCanvasContext.measureText(blockName).width + TOOLTIP_PADDING*2,
                    tooltipCanvasContext.measureText(coordStr).width + TOOLTIP_PADDING*2);

                tooltipCanvas.style.left = `${eventX - tooltipWidth}px`;
                tooltipCanvas.style.top = `${eventY - TOOLTIP_HEIGHT}px`;
                tooltipCanvas.style.position = "absolute";

                tooltipCanvasContext.fillStyle = TOOLTIP_BACKGROUND_COLOR;
                tooltipCanvasContext.strokeStyle = TOOLTIP_COLOR;
                tooltipCanvasContext.lineWidth = 2;
                // TODO: This is 2 seperate rects...?
                tooltipCanvasContext.fillRect(0, 0, tooltipWidth, TOOLTIP_HEIGHT);
                tooltipCanvasContext.strokeRect(0, 0, tooltipWidth, TOOLTIP_HEIGHT);
                tooltipCanvasContext.fillStyle = TOOLTIP_COLOR;
                tooltipCanvasContext.fillText(coordStr, TOOLTIP_PADDING, TOOLTIP_FONT_SIZE+2);
                tooltipCanvasContext.fillText(blockName, TOOLTIP_PADDING, TOOLTIP_FONT_SIZE*2);
            };
        }
    }, [props.mapData])

    function formatBlockName(blockName: string): string {
        return humanizeString(blockName.substring(blockName.indexOf(":") + 1));
    }

    return (
        <div id="gridContainer" style={{width: "100%", height: "100%"}}>
            <canvas id="gridCanvas" width="640" height="480" style={{cursor: "pointer"}}></canvas>
            <canvas id="tooltipCanvas" width={TOOLTIP_WIDTH_MAX} height={TOOLTIP_HEIGHT}></canvas>
        </div>
    )
}

export default TextureGrid;
