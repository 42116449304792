import React from "react";
import {Grid, Link, Typography} from "@mui/material";
import {LITEMATICA_GUIDE_PATH, WORLD_EDIT_GUIDE_PATH} from "../constants/route-constants";

function Information() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} textAlign={"center"} sx={{color: "#666", marginTop: "20px"}}>
                <Typography variant={"h4"}>Welcome to Minecraft Map Maker</Typography>
                <ol style={{listStylePosition: "inside"}}>
                    <li>Upload an image.</li>
                    <li>Hit generate to preview it's map.</li>
                    <li>Download it's schematic* or use the 2D rendering to recreate it in your own world.</li>
                </ol>
                <Typography variant="body1">
                    * Schematic files are compatible with most 3rd party mods, eg. WorldEdit, Litematica, MCEdit, ...
                </Typography>
                <Typography variant="body1">
                    <Link href={WORLD_EDIT_GUIDE_PATH}>Guide on placing a map schematic with WorldEdit.</Link><br/>
                    <Link href={LITEMATICA_GUIDE_PATH}>Guide on building maps with Litematica.</Link>
                </Typography>
                <Typography variant="body1">
                    By default we have chosen what we think are the most common or lowest cost blocks. This can be
                    changed on the settings tab or colors can be removed completely. Remember to regenerate after
                    making any changes to the settings.
                </Typography>
                <Typography variant="body1">
                    Please consider supporting me on <Link href="https://ko-fi.com/pricklysponge">Ko-fi</Link> to help
                    me continue improving this site.
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Information;
