import React from "react";
import {Box, Button, Container, Link, List, ListItem, Paper, Stack, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";
import {MAP_ART_CREATOR_PATH} from "../../constants/route-constants";

function WorldEdit() {
    const handleSchematicDownloadClick = () => {
        ReactGA.event({
            category: "MapMakerApp",
            action: "schematic_download",
            label: "treehouse",
        });
        return false;
    };

    return (
        <Container maxWidth="lg" sx={{marginTop: 2}}>
            <Helmet>
                <title>Placing Map Schematics with WorldEdit</title>
            </Helmet>
            <Stack spacing={2}>
                <Paper elevation={0}>
                    <Typography variant="h1" id="main-heading">
                        Placing a Minecraft Map Maker Schematic with WorldEdit
                    </Typography>
                    <Typography variant="body1">
                        This guide will walk you through using WorldEdit to place your generated map schematic in a
                        Minecraft world. You will need the WorldEdit mod for this. If you haven't already got a
                        Minecraft instance with WorldEdit installed, head on over to the
                        <Link href="https://worldedit.enginehub.org/en/latest/install/"
                              target="_blank">WorldEdit documentation
                        </Link> for options and guides on installing it. I personally use MultiMC with Fabric.
                    </Typography>
                    <List>
                        <ListItem><Link href="#prepare-schematic">Prepare a Schematic</Link></ListItem>
                        <ListItem><Link href="#obtain-fill-map">Obtaining and Filling a Map</Link></ListItem>
                        <ListItem><Link href="#position-for-placing">Position Yourself for Placing the Schematic</Link></ListItem>
                        <ListItem><Link href="#placing-schematic">Placing the Schematic</Link></ListItem>
                        <ListItem><Link href="#now-what">Now What?</Link></ListItem>
                    </List>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="prepare-schematic">
                        Prepare a Schematic
                    </Typography>
                    <Typography variant="body1">
                        Generate and download a schematic using our <Link href={ MAP_ART_CREATOR_PATH }>map art creator</Link>. Copy the
                        downloaded schematic to WorldEdit's schematics directory. This is usually located at
                        <Typography variant="code">
                            &lt;minecraft_installation_location&gt;/config/worldedit/schematics
                        </Typography> An easy way to find this directory is from the in-game menu to go to
                        <Typography variant="italic">
                            "Options" -&gt; "Resource Packs..." -&gt; "Open Pack Folder"
                        </Typography>. This should open up your file browser and you should be able to go up one
                        directory and then to <Typography variant="code">/config/worldedit/schematics</Typography>.
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="h2" id="obtain-fill-map">
                        Obtaining and Filling a Map
                    </Typography>
                    <Typography variant="body1">
                        Obtain a map. Depending on what type of world you are playing on, either craft or give yourself a map.
                        <ol>
                            <li>
                                Maps can be crafted with the following recipe:<br/>
                                <img src={`${process.env.PUBLIC_URL}/worldedit_guide/map_recipe.png`} alt="Map crafting recipe"></img>
                            </li>
                            <li>
                                Maps can be placed in your inventory by running this command(operator privileges will be needed), replacing <Typography variant="code">player_name</Typography> with your in-game name<br/>
                                <Typography variant="code">/give &lt;player_name&gt; minecraft:map</Typography>
                            </li>
                        </ol>
                        While standing within the area you wish to place the schematic, right click with the map in your hand. The following is important to note when preparing the area in which you will build or place the schematic:
                        <figure>
                            <blockquote cite="https://minecraft.fandom.com/wiki/Map#Mapping">
                                <p>The map does not center on the player when created, rather, the world is broken up into large invisible grid squares, and the map displays the area of whichever grid square it is in when it is first used.</p>
                            </blockquote>
                            <figcaption><Link href="https://minecraft.fandom.com/wiki/Map#Mapping" target="_blank">-Minecraft Wiki</Link></figcaption>
                        </figure>
                    </Typography>
                </Paper>
                <Paper elevation={0} id="position-for-placing">
                    <Typography variant="h2">
                        Position Yourself for Placing the Schematic
                    </Typography>
                    <Typography variant="body1">
                        The schematic will be placed in relation to where the player is standing. Make your way over to the furthest block in the top-left/north-west corner of the map.
                    </Typography>
                    <Box sx={{textAlign:"center"}}><img src={`${process.env.PUBLIC_URL}/worldedit_guide/player_position.png`} alt="Position player should stand in when placing schematic"></img></Box>
                    <Typography variant="body1">
                        Some tips to make positioning yourself on this block easier:
                        <ul>
                            <li>Enable chunk borders by pressing F3+G, this will help you find the corner most block of the corner most chunk within the map.</li>
                            <li>Once you are in the corner most chunk you could use the chunk position in the F3 debug info to find the corner most block. You will want to be standing on position <Typography variant="code">[0, y, 0]</Typography> within the chunk.</li>
                            <img src={`${process.env.PUBLIC_URL}/worldedit_guide/chunk_coords.png`} alt="Debug info chunk coordinates"></img>
                        </ul>
                    </Typography>
                </Paper>
                <Paper elevation={0} id="placing-schematic">
                    <Typography variant="h2">
                        Placing the Schematic
                    </Typography>
                    <Typography variant="body1">
                        Load the schematic with WorldEdit by running <Typography variant="code">//schematic load &lt;schematic_name&gt;</Typography> where <Typography variant="code">schematic_name</Typography> is replaced with the name of the file copied in Step 1 without the .schem extension. Run <Typography variant="code">//paste</Typography> to place the schematic.
                    </Typography>
                    <Typography variant="body1">
                        If the schematic is not correctly lined up within the map area, use a wooden axe to select the entire area of the map(right click the axe on the top nearest block, and left click the bottom block in the opposite diagonal corner).
                        Face in the direction that you need to move the area and run <Typography variant="code">//move &lt;block_count&gt;</Typography> where <Typography variant="code">block_count</Typography> is the number of blocks you need to shift the selection over by.
                    </Typography>
                    <Box sx={{ textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/worldedit_guide/placed_schematic.png`} alt="Map art in the world"></img>
                        <img src={`${process.env.PUBLIC_URL}/worldedit_guide/map_held.png`} alt="Map art held by player"></img>
                    </Box>
                </Paper>
                <Paper elevation={0} id="now-what">
                    <Typography variant="h2">
                        Now What?
                    </Typography>
                    <Typography variant="body1">
                        If there are any areas on the map that are not yet drawn or updated, move to that area while holding the map to get it to update.
                    </Typography>
                    <Typography variant="body1">
                        You can "frame" your artwork by placing the map in an item frame.
                    </Typography>
                    <Box sx={{textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/worldedit_guide/map_in_frame.png`} alt="Map art in item frame"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="body1">
                        Using a glow item frame will make the picture light up in the dark.
                    </Typography>
                    <Box sx={{textAlign: "center"}}>
                        <img src={`${process.env.PUBLIC_URL}/worldedit_guide/map_in_glow_frame.png`} alt="Map art in glow item frame"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant="body1">
                        You can lock the map with a glass pane at a cartography table so that it remains in it's current state and new changes to the area don't affect it.
                    </Typography>
                    <Box sx={{textAlign:"center"}}>
                        <img src={`${process.env.PUBLIC_URL}/worldedit_guide/lock_map.png`} alt="Locking a map in a cartography table"></img>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Box>
                        <Button variant="contained" startIcon={<Download />} href="/worldedit_guide/treehouse.schem" download onClick={handleSchematicDownloadClick}>
                            Download this Schematic
                        </Button>
                    </Box>
                </Paper>
                <Paper elevation={0}>
                    <Link href="https://www.freepik.com/free-vector/fantasy-tree-house-inside-tree-trunk_23813196.htm#query=fantasy%20forest&position=7&from_view=search&track=sph" target="_blank">Original Image by brgfx</Link> on Freepik
                </Paper>
            </Stack>
        </Container>
    );
}

export default WorldEdit;
