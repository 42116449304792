import React from 'react';
import './App.css';
import ReactGA from "react-ga4";
import {
    AppBar,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Menu, MenuItem,
    ThemeProvider,
    Toolbar,
    Typography
} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import theme from "./theme";
import MapMakerApp from "./components/MapMakerApp";
import PageNotFound from "./components/PageNotFound";
import WorldEdit from "./components/guides/WorldEdit";
import Litematica from "./components/guides/Litematica";
import {LITEMATICA_GUIDE_PATH, MAP_ART_CREATOR_PATH, WORLD_EDIT_GUIDE_PATH} from "./constants/route-constants";

ReactGA.initialize("G-7N5ZSV20X8");

function App() {
    const handleKofiClick = () => {
        ReactGA.event({
            category: "MapMakerApp",
            action: "kofi_click",
        });
        return false;
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box height="100vh" display="flex" flexDirection="column">
                <AppBar position="static">
                    <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
                        <Typography variant="h6" color="inherit" component="div"></Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ textAlign: 'center' }} href="/">
                                    <ListItemText primary="Map Maker" />
                                </ListItemButton>
                                <ListItemButton sx={{ textAlign: 'center' }}
                                                component="button"
                                                id="guides-button"
                                                aria-controls={open ? "guides-menu" : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? "true" : undefined}
                                                onClick={handleClick}
                                >
                                    <ListItemText primary="Guides" />
                                </ListItemButton>
                                <Menu
                                    id="guides-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'guides-button',
                                    }}
                                >
                                    <MenuItem component="a" href={ WORLD_EDIT_GUIDE_PATH }>WorldEdit</MenuItem>
                                    <MenuItem component="a" href={ LITEMATICA_GUIDE_PATH }>Litematica</MenuItem>
                                </Menu>
                                <ListItemButton sx={{ textAlign: 'center' }} href="/gallery" disabled>
                                    <ListItemText primary="Gallery" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Box>
                            <a href='https://ko-fi.com/X8X2FNQW1' target='_blank' rel="noreferrer">
                                <img height='36'
                                     style={{border: "0px", height: "36px"}}
                                     src='https://cdn.ko-fi.com/cdn/kofi2.png?v=3'
                                     alt='Buy Me a Coffee at ko-fi.com'
                                     onClick={handleKofiClick} />
                            </a>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box flex={1} overflow="auto" height="100%">
                    <Routes>
                        <Route path={ MAP_ART_CREATOR_PATH } element={<MapMakerApp />} />
                        <Route path={ WORLD_EDIT_GUIDE_PATH } element={<WorldEdit />} />
                        <Route path={ LITEMATICA_GUIDE_PATH } element={<Litematica />} />
                        {/*<Route path="/gallery" element={<Gallery />} />*/}
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default App;
