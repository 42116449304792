import React from "react";
import { Box, ButtonBase } from "@mui/material";

interface MapPreviewProps {
    previewUrl: string | undefined,
    width: number,
    height: number,
}

function MapPreview(props: MapPreviewProps) {
    let buttonContent;
    if(props.previewUrl) {
        buttonContent = <img src={props.previewUrl} alt="Map Preview" style={{width: props.width-4, height: props.height-4, objectFit: "contain"}} />;
    } else {
        buttonContent = "Preview"
    }

    return (
        <Box>
            <ButtonBase component="span" sx={{
                width: props.width,
                height: props.height,
                color: "#666666",
                backgroundColor: "#EEEEEE",
                border: "2px dashed #666666"}}
                        disabled>
                {buttonContent}
            </ButtonBase>
        </Box>
    );
}

export default MapPreview;
