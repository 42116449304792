import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, Tab, Tabs} from "@mui/material";
import ImageUpload from "./forms/map-maker/ImageUpload";
import MapPreview from "./MapPreview";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {API, Storage} from "aws-amplify";
import DownloadIcon from "@mui/icons-material/Download";
import ReactGA from "react-ga4";
import TextureGrid from "./texturegrid/TextureGrid";
import Information from "./Information";
import Settings from "./forms/map-maker/Settings";

export interface MapData {
    data: number[][];
    preview: string;
    schematic: string;
    blockPreference: Record<string, number>;
}

enum MapState {
    Pristine,
    Ready,
    Generating,
    Generated
}

type BlockPreference = {[key: string]: number}

function MapMakerApp() {
    const [mapMakerState, setMapMakerState] = useState<MapState>(MapState.Pristine);
    const [tab, setTab] = React.useState(0);

    const [imageId, setImageId] = useState<string | undefined>();
    const [blockPreference, setBlockPreference] = useState<BlockPreference>({});
    const [dimensions, setDimensions] = useState({width: 1, height: 1});
    const [mapData, setMapData] = useState<MapData | undefined>();
    const [previewUrl, setPreviewUrl] = useState<string | undefined>();
    const [schematicUrl, setSchematicUrl] = useState<string | undefined>();

    useEffect(() => {
        const getPreviewUrl = async () => {
            if(mapData?.preview) {
                const previewUrl = await Storage.get(mapData?.preview);
                setPreviewUrl(previewUrl);
            }
        }

        const getSchematicUrl = async () => {
            if(mapData?.schematic) {
                const schematicUrl = await Storage.get(mapData?.schematic);
                setSchematicUrl(schematicUrl);
            }
        }

        getPreviewUrl();
        getSchematicUrl();
    }, [mapData]);

    const handleImageUploaded = (id: string, mapName: string) => {
        setImageId(id);
        setMapMakerState(MapState.Ready);
    }

    const generate = async () => {
        setMapMakerState(MapState.Generating);

        API.post("m3api", "/map", {
            body: {
                imageId: imageId,
                width: dimensions.width,
                depth: dimensions.height,
                blockPreference: blockPreference
            }
        }).then(r => {
            setMapMakerState(MapState.Generated);
            setMapData({
                data: r['data'],
                preview: r['preview'],
                schematic: r['schematic'],
                blockPreference: r["blockPreference"],
            });
            setTab(2);
        });
    }

    const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
        setTab(newTab);
    };

    const handleBlockPreferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPreviewUrl(undefined);
        setSchematicUrl(""); // TODO: Undefined?
        setMapData(undefined);
        if(mapMakerState !== MapState.Pristine) {
            setMapMakerState(MapState.Ready);
        }

        const target = event.target;
        let updatedBlockPreference = blockPreference;
        updatedBlockPreference[target.name] = parseInt(target.value);
        setBlockPreference(updatedBlockPreference);
    }

    const handleDimensionChange = (width: number, height: number) => {
        setDimensions({width: width, height:height});
        setPreviewUrl(undefined);
        setSchematicUrl(""); // TODO: Undefined?
        setMapData(undefined);
        if(mapMakerState !== MapState.Pristine) {
            setMapMakerState(MapState.Ready);
        }
    }

    const handleSchematicDownloadClick = () => {
        ReactGA.event({
            category: "MapMakerApp",
            action: "schematic_download",
        });
        return false;
    }

    return (
        <Grid container sx={{height: "100%"}}>
            <Grid item md={2} sx={{backgroundColor:"#ccc"}}>
                <Grid item container direction="column" spacing={2} p={2}>
                    <Grid item textAlign="center">
                        <ImageUpload width={164} height={164} onImageUploaded={handleImageUploaded}></ImageUpload>
                    </Grid>
                    <Grid item textAlign="center">
                        <Button variant="contained"
                                color="success"
                                disabled={mapMakerState !== MapState.Ready}
                                onClick={generate}
                                sx={{marginRight: 1}}>
                            {mapMakerState === MapState.Generating
                                ? <span><CircularProgress size="1rem"/> Generate</span>
                                : "Generate"}
                        </Button>
                    </Grid>
                    <Grid item textAlign="center">
                        <ArrowDownwardIcon fontSize="large" sx={{marginTop: "auto", marginBottom: "auto"}}></ArrowDownwardIcon>
                    </Grid>
                    <Grid item textAlign="center">
                        <MapPreview previewUrl={previewUrl} width={164} height={164} />
                    </Grid>
                    <Grid item textAlign="center">
                        <Button variant="contained"
                                startIcon={<DownloadIcon/>}
                                disabled={mapData === undefined}
                                href={schematicUrl || ""} target={"_blank"}
                                onClick={handleSchematicDownloadClick}>
                            Schematic
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={10} sx={{height: "100%", width: "100%", overflow: "auto"}} id="mapmakerapp-container">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Info" />
                        <Tab label="Settings" />
                        <Tab label="2D Map" disabled={mapMakerState !== MapState.Generated} />
                    </Tabs>
                </Box>
                <Box role="tabpanel" hidden={tab !== 0} id={`tabpanel-0`}>
                    <Information/>
                </Box>
                <Box role="tabpanel" hidden={tab !== 1} id={`tabpanel-1`}>
                    <Settings handleBlockPreferenceChange={handleBlockPreferenceChange} handleDimensionChange={handleDimensionChange}></Settings>
                </Box>
                <Box role="tabpanel" hidden={tab !== 2} id={`tabpanel-2`}>
                    <TextureGrid mapData={mapData}/>
                </Box>
            </Grid>
        </Grid>
    )
}

export default MapMakerApp;
