import React from "react";
import {Box, Container, Paper, Stack, Typography} from "@mui/material";

function PageNotFound() {
    return (
        <Container sx={{marginTop: 2}}>
            <Stack>
                <Paper elevation={0}>
                    <Typography variant="h1" textAlign="center">Vwoops!</Typography>
                    <Typography variant="h2" textAlign="center">Looks like this enderman stole the page you were looking for.</Typography>
                </Paper>
                <Box textAlign="center">
                    <img src={`${process.env.PUBLIC_URL}/enderman_holding_block.png`} alt="Enderman holding block"></img>
                </Box>
            </Stack>
        </Container>
    )
}

export default PageNotFound