import React, {useState} from "react";
import { v4 } from "uuid";
import { Storage } from "aws-amplify";
import { Box, ButtonBase, CircularProgress } from "@mui/material";

interface ImageUploadProps {
    width: number,
    height: number,
    onImageUploaded: (id: string, mapName: string) => void
}

function ImageUpload(props: ImageUploadProps) {
    const [imagePreview, setImagePreview] = useState<string | undefined>();
    const [isUploading, setIsUploading] = useState(false);

    const handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
        if(event.currentTarget === null || event.currentTarget.files === null || event.currentTarget.files.length === 0) {
            // No file selected. Returning.
            return
        }

        const imageId = v4();
        const imageAsFile = event.currentTarget.files[0];
        const expiry = new Date().getTime() + (60 * 60 * 24 * 7);
        try {
            setIsUploading(true)
            await Storage.put(imageId + '/original.png', imageAsFile, {
                contentType: 'image/png',
                expires: new Date(expiry)
            });
            setImagePreview(URL.createObjectURL(imageAsFile));
            setIsUploading(false);
            props.onImageUploaded(imageId, imageAsFile.name.split('.')[0]);
        } catch(error) {
            console.error('Error uploading file: ', error)
        }
    }

    let buttonContent;
    if(isUploading) {
        buttonContent = <Box sx={{ display: 'flex' }}><CircularProgress /></Box>;
    } else {
        if(imagePreview) {
            buttonContent = <img src={imagePreview} alt="Map Source" style={{width: props.width-4, height: props.height-4, objectFit: "contain"}} />;
        } else {
            buttonContent = "Click to Upload";
        }
    }

    return (
        <Box>
            <input type="file" id="image-input" style={{display: "none"}} accept="image/png, image/gif, image/jpeg" onChange={handleChange}/>
            <label htmlFor="image-input">
                <ButtonBase component="span" sx={{
                    width: props.width,
                    height: props.height,
                    color: "#666666",
                    backgroundColor: "#EEEEEE",
                    border: "2px dashed #666666"}}>
                    {buttonContent}
                </ButtonBase>
            </label>
        </Box>
    );
}

export default ImageUpload;
