import {createTheme} from "@mui/material";

declare module "@mui/material/styles/createTypography" {
    interface Typography {
        code: React.CSSProperties;
        italic: React.CSSProperties;
        bold: React.CSSProperties;
    }

    interface TypographyOptions {
        code?: React.CSSProperties;
        italic?: React.CSSProperties;
        bold?: React.CSSProperties;
    }
}

declare module "@mui/material/Typography/Typography" {
    interface TypographyPropsVariantOverrides {
        code: true;
        italic: true;
        bold: true;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            light: "#87B37B",
            main: "#426539",
            dark: "#214D15",
        },
        secondary: {
            light: "#AE8A70",
            main: "#7b573d",
            dark: "#48240A",
        },
    },
    typography: {
        h1: {
            fontSize: "3.4em",
        },
        h2: {
            fontSize: "2em",
        },
        code: {
            fontFamily: "Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono,monospace",
            backgroundColor: "#EEEEEE",
        },
        italic: {
          fontStyle: "italic",
        },
        bold: {
            fontWeight: "bold",
        },
    },
});

export default theme;
