export type ColorsType = {[key: string]: string[]}

const color_json = `{
  "31744": [
    "minecraft:oak_leaves",
    "minecraft:birch_leaves",
    "minecraft:jungle_leaves",
    "minecraft:spruce_leaves",
    "minecraft:dark_oak_leaves",
    "minecraft:acacia_leaves",
    "minecraft:azalea_leaves",
    "minecraft:flowering_azalea_leaves",
    "minecraft:mangrove_leaves"
  ],
  "55610": [
    "minecraft:emerald_block"
  ],
  "1356933": [
    "minecraft:warped_wart_block"
  ],
  "1474182": [
    "minecraft:warped_nylium",
    "minecraft:waxed_oxidized_cut_copper_slab",
    "minecraft:waxed_oxidized_cut_copper",
    "minecraft:waxed_oxidized_copper"
  ],
  "1644825": [
    "minecraft:black_carpet",
    "minecraft:black_wool",
    "minecraft:black_concrete",
    "minecraft:black_concrete_powder",
    "minecraft:basalt",
    "minecraft:smooth_basalt",
    "minecraft:polished_basalt",
    "minecraft:blackstone",
    "minecraft:blackstone_slab",
    "minecraft:polished_blackstone",
    "minecraft:polished_blackstone_slab",
    "minecraft:polished_blackstone_bricks",
    "minecraft:polished_blackstone_brick_slab",
    "minecraft:chiseled_polished_blackstone",
    "minecraft:cracked_polished_blackstone_bricks",
    "minecraft:gilded_blackstone",
    "minecraft:obsidian",
    "minecraft:crying_obsidian",
    "minecraft:coal_block",
    "minecraft:ancient_debris",
    "minecraft:netherite_block",
    "minecraft:black_glazed_terracotta",
    "minecraft:black_stained_glass",
    "minecraft:sculk_catalyst"
  ],
  "2430480": [
    "minecraft:black_terracotta"
  ],
  "3361970": [
    "minecraft:blue_carpet",
    "minecraft:blue_wool",
    "minecraft:blue_concrete",
    "minecraft:blue_concrete_powder",
    "minecraft:blue_glazed_terracotta",
    "minecraft:blue_stained_glass"
  ],
  "3746083": [
    "minecraft:gray_terracotta",
    "minecraft:tuff"
  ],
  "3837580": [
    "minecraft:warped_slab",
    "minecraft:warped_planks",
    "minecraft:stripped_warped_stem",
    "minecraft:waxed_weathered_cut_copper_slab",
    "minecraft:waxed_weathered_cut_copper",
    "minecraft:waxed_weathered_copper"
  ],
  "4882687": [
    "minecraft:lapis_block"
  ],
  "4993571": [
    "minecraft:brown_terracotta",
    "minecraft:dripstone_block"
  ],
  "4996700": [
    "minecraft:blue_terracotta"
  ],
  "5000268": [
    "minecraft:gray_carpet",
    "minecraft:gray_wool",
    "minecraft:gray_concrete",
    "minecraft:gray_concrete_powder",
    "minecraft:acacia_wood",
    "minecraft:gray_glazed_terracotta",
    "minecraft:gray_stained_glass",
    "minecraft:tinted_glass"
  ],
  "5001770": [
    "minecraft:green_terracotta"
  ],
  "5013401": [
    "minecraft:cyan_carpet",
    "minecraft:cyan_wool",
    "minecraft:cyan_concrete",
    "minecraft:cyan_concrete_powder",
    "minecraft:prismarine_slab",
    "minecraft:prismarine",
    "minecraft:cyan_glazed_terracotta",
    "minecraft:cyan_stained_glass",
    "minecraft:sculk_sensor"
  ],
  "5647422": [
    "minecraft:warped_hyphae",
    "minecraft:stripped_warped_hyphae"
  ],
  "5725276": [
    "minecraft:cyan_terracotta",
    "minecraft:mud"
  ],
  "6035741": [
    "minecraft:crimson_hyphae",
    "minecraft:stripped_crimson_hyphae"
  ],
  "6085589": [
    "minecraft:prismarine_brick_slab",
    "minecraft:prismarine_bricks",
    "minecraft:dark_prismarine_slab",
    "minecraft:dark_prismarine",
    "minecraft:diamond_block"
  ],
  "6579300": [
    "minecraft:cobbled_deepslate_slab",
    "minecraft:cobbled_deepslate",
    "minecraft:deepslate",
    "minecraft:polished_deepslate_slab",
    "minecraft:polished_deepslate",
    "minecraft:deepslate_brick_slab",
    "minecraft:deepslate_bricks",
    "minecraft:deepslate_tile_slab",
    "minecraft:deepslate_tiles",
    "minecraft:cracked_deepslate_bricks",
    "minecraft:cracked_deepslate_tiles",
    "minecraft:chiseled_deepslate"
  ],
  "6704179": [
    "minecraft:brown_carpet",
    "minecraft:brown_wool",
    "minecraft:brown_concrete",
    "minecraft:brown_concrete_powder",
    "minecraft:dark_oak_slab",
    "minecraft:dark_oak_planks",
    "minecraft:dark_oak_log",
    "minecraft:stripped_dark_oak_log",
    "minecraft:dark_oak_wood",
    "minecraft:stripped_dark_oak_wood",
    "minecraft:soul_sand",
    "minecraft:soul_soil",
    "minecraft:mud_brick_slab",
    "minecraft:brown_glazed_terracotta",
    "minecraft:brown_stained_glass"
  ],
  "6717235": [
    "minecraft:green_carpet",
    "minecraft:green_wool",
    "minecraft:moss_carpet",
    "minecraft:moss_block",
    "minecraft:green_concrete",
    "minecraft:green_concrete_powder",
    "minecraft:dried_kelp_block",
    "minecraft:green_glazed_terracotta",
    "minecraft:green_stained_glass"
  ],
  "6724056": [
    "minecraft:light_blue_carpet",
    "minecraft:light_blue_wool",
    "minecraft:light_blue_concrete",
    "minecraft:light_blue_concrete_powder",
    "minecraft:light_blue_stained_glass",
    "minecraft:light_blue_glazed_terracotta"
  ],
  "6780213": [
    "minecraft:lime_terracotta"
  ],
  "7340544": [
    "minecraft:nether_brick_slab",
    "minecraft:nether_bricks",
    "minecraft:red_nether_brick_slab",
    "minecraft:red_nether_bricks",
    "minecraft:magma_block",
    "minecraft:cracked_nether_bricks",
    "minecraft:chiseled_nether_bricks"
  ],
  "7367818": [
    "minecraft:light_blue_terracotta"
  ],
  "7368816": [
    "minecraft:cobblestone_slab",
    "minecraft:cobblestone",
    "minecraft:stone_slab",
    "minecraft:stone",
    "minecraft:andesite_slab",
    "minecraft:andesite",
    "minecraft:polished_andesite_slab",
    "minecraft:polished_andesite",
    "minecraft:stone_brick_slab",
    "minecraft:stone_bricks",
    "minecraft:smooth_stone_slab",
    "minecraft:smooth_stone",
    "minecraft:mossy_cobblestone_slab",
    "minecraft:mossy_cobblestone",
    "minecraft:mossy_stone_brick_slab",
    "minecraft:mossy_stone_bricks",
    "minecraft:chiseled_stone_bricks",
    "minecraft:cracked_stone_bricks",
    "minecraft:gravel",
    "minecraft:observer"
  ],
  "8014168": [
    "minecraft:purple_terracotta"
  ],
  "8339378": [
    "minecraft:purple_carpet",
    "minecraft:purple_wool",
    "minecraft:purple_concrete",
    "minecraft:purple_concrete_powder",
    "minecraft:mycelium",
    "minecraft:amethyst_block",
    "minecraft:purple_glazed_terracotta",
    "minecraft:purple_stained_glass"
  ],
  "8365974": [
    "minecraft:verdant_froglight"
  ],
  "8368696": [
    "minecraft:grass_block",
    "minecraft:slime_block"
  ],
  "8375321": [
    "minecraft:lime_carpet",
    "minecraft:lime_wool",
    "minecraft:lime_concrete",
    "minecraft:lime_concrete_powder",
    "minecraft:melon",
    "minecraft:lime_glazed_terracotta",
    "minecraft:lime_stained_glass"
  ],
  "8476209": [
    "minecraft:spruce_slab",
    "minecraft:spruce_planks",
    "minecraft:spruce_log",
    "minecraft:stripped_spruce_log",
    "minecraft:spruce_wood",
    "minecraft:stripped_spruce_wood",
    "minecraft:podzol",
    "minecraft:mangrove_roots"
  ],
  "8874850": [
    "minecraft:light_gray_terracotta",
    "minecraft:waxed_exposed_cut_copper_slab",
    "minecraft:waxed_exposed_cut_copper",
    "minecraft:waxed_exposed_copper",
    "minecraft:mud_bricks"
  ],
  "9321518": [
    "minecraft:red_terracotta"
  ],
  "9402184": [
    "minecraft:oak_slab",
    "minecraft:oak_planks",
    "minecraft:oak_log",
    "minecraft:stripped_oak_log",
    "minecraft:oak_wood",
    "minecraft:stripped_oak_wood",
    "minecraft:crafting_table",
    "minecraft:bookshelf",
    "minecraft:note_block",
    "minecraft:fletching_table",
    "minecraft:barrel",
    "minecraft:loom"
  ],
  "9715553": [
    "minecraft:crimson_slab",
    "minecraft:crimson_planks",
    "minecraft:crimson_stem",
    "minecraft:stripped_crimson_stem"
  ],
  "9787244": [
    "minecraft:magenta_terracotta"
  ],
  "9923917": [
    "minecraft:dirt",
    "minecraft:coarse_dirt",
    "minecraft:rooted_dirt",
    "minecraft:packed_mud",
    "minecraft:granite_slab",
    "minecraft:granite",
    "minecraft:polished_granite_slab",
    "minecraft:polished_granite",
    "minecraft:jungle_slab",
    "minecraft:jungle_planks",
    "minecraft:jungle_log",
    "minecraft:stripped_jungle_log",
    "minecraft:jungle_wood",
    "minecraft:stripped_jungle_wood",
    "minecraft:brown_mushroom_block"
  ],
  "10040115": [
    "minecraft:red_carpet",
    "minecraft:red_wool",
    "minecraft:red_concrete",
    "minecraft:red_concrete_powder",
    "minecraft:bricks",
    "minecraft:brick_slab",
    "minecraft:red_glazed_terracotta",
    "minecraft:red_stained_glass",
    "minecraft:shroomlight",
    "minecraft:red_mushroom_block",
    "minecraft:nether_wart_block",
    "minecraft:mangrove_slab",
    "minecraft:mangrove_planks",
    "minecraft:mangrove_log",
    "minecraft:stripped_mangrove_log",
    "minecraft:mangrove_wood",
    "minecraft:stripped_mangrove_wood"
  ],
  "10066329": [
    "minecraft:light_gray_carpet",
    "minecraft:light_gray_wool",
    "minecraft:light_gray_concrete",
    "minecraft:light_gray_concrete_powder",
    "minecraft:light_gray_stained_glass",
    "minecraft:light_gray_glazed_terracotta"
  ],
  "10441252": [
    "minecraft:orange_terracotta"
  ],
  "10505550": [
    "minecraft:pink_terracotta"
  ],
  "10526975": [
    "minecraft:frosted_ice",
    "minecraft:blue_ice",
    "minecraft:packed_ice"
  ],
  "10791096": [
    "minecraft:clay"
  ],
  "10987431": [
    "minecraft:iron_block"
  ],
  "11685080": [
    "minecraft:magenta_carpet",
    "minecraft:magenta_wool",
    "minecraft:magenta_concrete",
    "minecraft:magenta_concrete_powder",
    "minecraft:purpur_slab",
    "minecraft:purpur_block",
    "minecraft:purpur_pillar",
    "minecraft:magenta_glazed_terracotta",
    "minecraft:magenta_stained_glass"
  ],
  "12223780": [
    "minecraft:yellow_terracotta"
  ],
  "12398641": [
    "minecraft:crimson_nylium"
  ],
  "13092807": [
    "minecraft:mushroom_stem",
    "minecraft:cobweb"
  ],
  "13742497": [
    "minecraft:white_terracotta",
    "minecraft:calcite"
  ],
  "14188339": [
    "minecraft:orange_carpet",
    "minecraft:orange_wool",
    "minecraft:orange_concrete",
    "minecraft:orange_concrete_powder",
    "minecraft:terracotta",
    "minecraft:pumpkin",
    "minecraft:carved_pumpkin",
    "minecraft:jack_o_lantern",
    "minecraft:red_sand",
    "minecraft:red_sandstone_slab",
    "minecraft:red_sandstone",
    "minecraft:chiseled_red_sandstone",
    "minecraft:smooth_red_sandstone_slab",
    "minecraft:smooth_red_sandstone",
    "minecraft:cut_red_sandstone_slab",
    "minecraft:cut_red_sandstone",
    "minecraft:raw_copper_block",
    "minecraft:waxed_cut_copper_slab",
    "minecraft:waxed_cut_copper",
    "minecraft:waxed_copper_block",
    "minecraft:acacia_slab",
    "minecraft:acacia_planks",
    "minecraft:acacia_log",
    "minecraft:stripped_acacia_log",
    "minecraft:stripped_acacia_wood",
    "minecraft:honeycomb_block",
    "minecraft:honey_block",
    "minecraft:orange_glazed_terracotta",
    "minecraft:orange_stained_glass"
  ],
  "14200723": [
    "minecraft:raw_iron_block"
  ],
  "15066419": [
    "minecraft:yellow_carpet",
    "minecraft:yellow_wool",
    "minecraft:yellow_concrete",
    "minecraft:yellow_concrete_powder",
    "minecraft:hay_block",
    "minecraft:sponge",
    "minecraft:bee_nest",
    "minecraft:yellow_glazed_terracotta",
    "minecraft:yellow_stained_glass"
  ],
  "15892389": [
    "minecraft:pink_carpet",
    "minecraft:pink_wool",
    "minecraft:pink_concrete",
    "minecraft:pink_concrete_powder",
    "minecraft:pearlescent_froglight",
    "minecraft:pink_stained_glass",
    "minecraft:pink_glazed_terracotta"
  ],
  "16247203": [
    "minecraft:birch_slab",
    "minecraft:birch_planks",
    "minecraft:birch_log",
    "minecraft:stripped_birch_log",
    "minecraft:birch_wood",
    "minecraft:stripped_birch_wood",
    "minecraft:sand",
    "minecraft:sandstone_slab",
    "minecraft:sandstone",
    "minecraft:smooth_sandstone_slab",
    "minecraft:smooth_sandstone",
    "minecraft:cut_sandstone_slab",
    "minecraft:cut_sandstone",
    "minecraft:chiseled_sandstone",
    "minecraft:end_stone",
    "minecraft:end_stone_brick_slab",
    "minecraft:end_stone_bricks",
    "minecraft:bone_block",
    "minecraft:glowstone",
    "minecraft:scaffolding",
    "minecraft:ochre_froglight"
  ],
  "16445005": [
    "minecraft:gold_block",
    "minecraft:raw_gold_block"
  ],
  "16711680": [
    "minecraft:redstone_block",
    "minecraft:tnt"
  ],
  "16776437": [
    "minecraft:diorite_slab",
    "minecraft:diorite",
    "minecraft:polished_diorite_slab",
    "minecraft:polished_diorite",
    "minecraft:quartz_slab",
    "minecraft:quartz_block",
    "minecraft:smooth_quartz_slab",
    "minecraft:smooth_quartz",
    "minecraft:quartz_bricks",
    "minecraft:quartz_pillar",
    "minecraft:chiseled_quartz_block",
    "minecraft:target",
    "minecraft:sea_lantern"
  ],
  "16777215": [
    "minecraft:white_carpet",
    "minecraft:white_wool",
    "minecraft:white_concrete",
    "minecraft:white_concrete_powder",
    "minecraft:snow_block",
    "minecraft:powder_snow",
    "minecraft:white_glazed_terracotta",
    "minecraft:white_stained_glass"
  ]
}`;

const colors: ColorsType = JSON.parse(color_json);

export default colors;
