import React, {Fragment} from "react";
import {Grid, Radio, RadioGroup, Tooltip, useRadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import humanizeString from "humanize-string";

interface TextureRadioGroupProps {
    index: number;
    color: string;
    textures: string[];
    handleBlockPreferenceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface TextureRadioGroupLabelProps {
    x: number;
    y: number;
    label: string;
}

function TextureRadioGroupLabel(props: TextureRadioGroupLabelProps) {
    const radioGroup = useRadioGroup();
    const base = 28;

    let checked = false;
    if(radioGroup) {
        checked = radioGroup.value === props.x.toString();
    }

    let border = "2px solid white";
    if(checked) {
        border = "2px solid red"
    }

    const style = {
        backgroundImage: `url('${process.env.PUBLIC_URL}/selector_textures.png')`,
        width: `${base}px`,
        height: `${base}px`,
        backgroundPosition: `${(-base*props.x)}px ${(-base*props.y)}px`,
        border: border,
    };
    const labelText = props.label.substring(props.label.indexOf(":") + 1);

    return (
        <Tooltip title={humanizeString(labelText)} disableInteractive><div style={style}></div></Tooltip>
    )
}

function ColorRadioGroupLabel(props: {value: number}) {
    const base = 28;
    const radioGroup = useRadioGroup();

    let checked = false;
    let color = "#FFFFFF";
    if(radioGroup) {
        checked = radioGroup.value === props.value.toString();
        const radioGroupName = radioGroup.name;
        if(radioGroupName) {
            let hexColorStr = parseInt(radioGroupName).toString(16);
            const hexColorStrLen = hexColorStr.length;
            for(let i = 0; i < (6-hexColorStrLen); i++) {
                hexColorStr = "0".concat(hexColorStr);
            }
            color = `#${hexColorStr}`
        }
    }

    let border = "2px solid white";
    if(checked) {
        border = "2px solid red"
    }

    const style = {
        backgroundColor: color,
        width: `${base}px`,
        height: `${base}px`,
        border: border,
        color: "#FF0000",
        "text-align": "center",
        "font-size": "18px"
    };

    return (
        <div style={style}>{checked ? "X" : ""}</div>
    )
}

function TextureRadioGroup(props: TextureRadioGroupProps) {
    return (
        <Fragment>
            <RadioGroup row
                        style={{padding:"5px 0px 5px 0px"}}
                        name={props.color}
                        onChange={props.handleBlockPreferenceChange} defaultValue={"0"}>
                <Grid container>
                    <Grid item xs={1} textAlign={"right"}>
                        <FormControlLabel sx={{margin:"0 5px 0 0"}} key={`-1-${props.index}`} value={-1}
                                          control={<Radio sx={{display:"none"}}/>}
                                          label={ <ColorRadioGroupLabel value={-1}/>}/>
                    </Grid>
                    <Grid item xs={11}>
                        <div style={{marginLeft:"7px"}}>
                            {[...Array(props.textures.length)].map(function(object, i){
                                return <FormControlLabel sx={{margin:0}} key={`${i}-${props.index}`} value={i}
                                                         control={<Radio sx={{display:"none"}}/>}
                                                         label={ <TextureRadioGroupLabel label={props.textures[i]} x={i} y={props.index}/>}
                                />
                            })}
                        </div>
                    </Grid>
                </Grid>
            </RadioGroup>
        </Fragment>
    );
}

export default TextureRadioGroup;